<template>
  <div class="globalMap py-10">
    <v-row>
      <v-col md="1"></v-col>
      <v-col class="py-10" md="1">
        <!-- <v-col class="py-2">
          <v-btn style="min-width: 150px;" color="white" @click="goToServices" rounded>
            {{ $t('Our Services') }}
          </v-btn>
        </v-col>
        <v-col class="py-2">
          <v-btn style="min-width: 150px;" color="white" @click="goToArticle" rounded>
            {{ $t('Blogs') }}
          </v-btn>
        </v-col>
        <v-col class="py-2">
          <v-btn style="min-width: 150px;" color="white" rounded @click="changeMode">
            {{ isDarkMode ? $t('Light') : $t('Dark') }}
            <v-icon v-if="isDarkMode"> mdi-white-balance-sunny </v-icon>
            <v-icon v-else> mdi-moon-waxing-crescent </v-icon>

          </v-btn>
        </v-col> -->
      </v-col>
      <v-col md="5">
        <v-col class="py-4" v-for="(item,index) in mainViews">
          <v-row>
            <div style="width: 50px;">
              <v-fade-transition mode="out-in">
                <v-avatar size="45" >
                <img v-if="item.image" :src="getImgUrl(item.image.path)" style=" width: 90%;  " />
              </v-avatar>
              </v-fade-transition>
            </div>
            <v-col>
              <h3 style="color: var(--bg-color--font);">{{ item.value }}</h3>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
   

      <v-col class="py-15" md="4">
        <div style="width: 400px;">
          <v-fade-transition mode="out-in">
            <v-img v-if="isDarkMode" src="@/assets/Logo-white.png" style=" width: 90%;  "></v-img>
            <v-img v-else src="@/assets/Logo-dark.png" style=" width: 90%;  "></v-img>
          </v-fade-transition>
        </div>
      </v-col>
      <v-col nd="1"></v-col>
    </v-row>
  </div>
</template>
        
<script>
import axios from 'axios';


export default {
  name: "map",

  data() {
    return {
      isDarkMode: this.$store.state.isDarkMode,
      mainViews: [],
    };
  },
  watch: {
    "$store.state.isDarkMode": function () {
      this.isDarkMode = this.$store.state.isDarkMode;
    },
  },
  methods: {
    goToServices() {
      // this.fetchServices();
      document.querySelector('.services-content').scrollIntoView({ behavior: 'smooth' });
    },
    goToArticle() {
      document.querySelector('.article-content').scrollIntoView({ behavior: 'smooth' });
    },
    changeMode() {
      this.isDarkMode = !this.isDarkMode;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode);
      if (this.isDarkMode) {
        document.documentElement.setAttribute('data-theme', 'dark');
        this.srcImg = '@/assets/Logo-white.png';
      } else {
        localStorage.setItem('theme', 'light');
        document.documentElement.setAttribute('data-theme', 'light');
      }
    },
    getImgUrl(item) {
      let domain = "https://back-ai.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
  },
  async created() {
    try {
      //loading
      const response = await axios.get("social", {
        params: {
        },
      });
      this.mainViews = response.data.data;
    } catch (err) {
    } finally {
    }
  },
};
</script>
        
<style></style>
        