<template>
  <div>
    <div class="globalRadiusViewClients py-10">
      <v-col class="text-center" md="12">
        <h1 style="font-size: 36px; color: var(--base-color2);">Our Clients</h1>
      </v-col>
      <v-row>
        <v-col md="1"></v-col>
        <v-col md="10">
          <v-row class=" d-flex justify-center align-center">
            <template v-for="(item, index) in mainViews">
              <v-col class=" d-flex justify-center align-center" md="3" :key="index" style="margin-top: 2%;">
                <v-card height="142px" width="242px">
                  <v-card-title class=" d-flex justify-center align-center">
                    <div style="height: 70px; width: 110px; text-align: center;">
                      <img v-if="item.image" :src="getImgUrl(item.image.path)" style=" height: 70px; width: 100px; object-fit: cover;" />
                    </div>
                  </v-card-title>
                  <v-card-text class=" d-flex justify-center align-center">
                    <h2 style="color: var(--bg-color--font); font-size: 12px;"> {{ $t(item.name) }} | {{ $t(item.location)
                    }} </h2>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col md="1"></v-col>
      </v-row>
    </div>
  </div>
</template>
      
<script>
import axios from "axios";
export default {
  name: "clients",

  data() {
    return {
      mainViews: []
    };
  },
  methods: {
    getImgUrl(item) {
      let domain = "https://back-ai.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
  },
  async created() {
    try {
      //loading
      const response = await axios.get("client", {
        params: {
        },
      });
      this.mainViews = response.data.data;
    } catch (err) {
    } finally {
    }
  },
};
</script>
      
<style></style>
      