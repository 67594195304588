<template>
  
    <div class="globalRadiusViewArticle py-10" style="padding: 1%;">
      <v-col class="text-center" md="12">
        <h1 style="color: var(--base-color2);">Read Our Blog</h1>
      </v-col>

    <template>
        <v-window v-model="onboarding" show-arrows="hover">
          <v-window-item v-for="item in  list ">
            <v-card elevation="2"  class="d-flex align-center justify-center ma-2">
              <v-row>
                <v-col md="1"></v-col>
                <v-col class="py-10" md="7">
                  <v-col>
                   <h2> {{ item.title }}</h2>
                  </v-col>
                  <v-col>
                    <p>{{ item.text }}</p>
                  </v-col>
                </v-col>
                <v-col class="py-10" md="4">
                  <div style="width: 400px; height: 350px;">
                    <v-fade-transition mode="out-in">
                      <img v-if="item.image" :src="getImgUrl(item.image.path)" style="width: 90%; height: 90%;  " />
                    </v-fade-transition>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>
      </template> 
    </div>

</template>
      
<script>
import axios from 'axios';

export default {
  name: "article",

  data() {
    return {
      length: 3,
      onboarding: 0,
      list: [],
    };
  },
  methods: {
    getImgUrl(item) {
      let domain = "https://back-ai.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
  },
async  created() {
  const res = await axios.get("article");
  this.list = res.data.data
  },
};
</script>
      
<style></style>
      