<template>
  <div class="globalRadiusViewAbout">
    <img src="@/assets/Img-2.jpg" class="home-img-about" />
    <v-col style="position: absolute; " class="text-center py-10" md="12">
      <h1 style="font-size: 36px; color: var(--base-color1);">About Us</h1>
    </v-col>
    <v-row style="margin-top: 6%; position: absolute; ">
      <v-col md="2"></v-col>
      <v-col md="8">
        <template v-for="(item, index) in about">
          <div :key="index" style="margin-top: 50px; ">
            <h2 style="font-size: 30px; color: var(--base-color1); margin-top: 7%; margin-bottom: 1%;"> {{ $t(item.title)
            }} </h2>
            <h3 style="color: #FFFFFF; font-weight: lighter;">{{ $t(item.text) }}</h3>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
      
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "about",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      about: "about/getAbout",
    }),
  },
  methods: {
    ...mapActions({
      fetchAbout: "about/fetchAbout",
    }),
  },
  created() {
    this.fetchAbout();
  },
};
</script>
      
<style></style>
      