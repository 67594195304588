<template>
  <div class="globalRadiusViewServices">
    <div style="height: 50px;"></div>
    <v-col md="12">
      <v-row class="justify-center align-center">
        <h1 style="font-size: 40px; color: var(--base-color2);"> {{ $t('Our Services') }}</h1>
      </v-row>
    </v-col>
    <v-col md="12">
      <v-row>
        <template v-for="(item, index) in services">
          <v-row style="margin-top: 50px; " v-if="index < 4">
            <v-row v-if="index == 0 || index == 2">
              <v-col md="1"></v-col>
              <v-col md="4">
                <div style="width: 414px; height: 301px;">
                  <!-- :duration="{ enter: 4000, leave: 4000 }" -->
                  <v-fade-transition mode="out-in">
                    <v-img :src="getImgUrl(item.image.path)" style="max-width: 414px; max-height: 301px; "></v-img>
                  </v-fade-transition>
                </div>
              </v-col>
              <v-col md="6">
                <h2 style="color: var(--base-color1); margin-top: 7%; margin-bottom: 4%;"> {{ $t(item.name) }} </h2>
                <h3 style="color: var(--bg-color--font); font-weight: lighter;">{{ $t(item.description) }}</h3>
              </v-col>
              <v-col md="1"></v-col>
            </v-row>
            <v-row v-else>
              <v-col md="1"></v-col>
              <v-col md="6">
                <h2 style="color: var(--base-color1);  margin-top: 7%; margin-bottom: 4%;"> {{ $t(item.name) }} </h2>
                <h3 style="color: var(--bg-color--font); font-weight: lighter;">{{ $t(item.description) }}</h3>
              </v-col>
              <v-col md="4">
                <div style="width: 414px; height: 301px;">
                  <v-fade-transition mode="out-in">
                    <v-img :src="getImgUrl(item.image.path)" style="  max-width: 414px; max-height: 301px;   "></v-img>
                  </v-fade-transition>
                </div>
              </v-col>
              <v-col md="1"></v-col>
            </v-row>
          </v-row>
          <v-col v-else md="6">
            <v-row style="margin-top: 50px; ">
              <v-col v-if="index % 2 == 0" md="2"></v-col>
              <v-col md="4">
                <div style="width: 195px; height: 195px;">
                  <v-fade-transition mode="out-in">
                    <v-img :src="getImgUrl(item.image.path)" style="  max-width: 195px; max-height: 195px;"></v-img>
                  </v-fade-transition>
                </div>
              </v-col>
              <v-col md="6">
                <h2 style="color: var(--base-color1);   margin-bottom: 2%;"> {{ $t(item.name) }} </h2>
                <h4 style="color: var(--bg-color--font); font-weight: lighter;">{{ $t(item.description) }}</h4>
              </v-col>
              <v-col v-if="index % 2 == 1" md="2"></v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <div style="height: 70px;"></div>
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "services",


  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      services: "services/getServices",
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: "services/fetchServices",
    }),
    getImgUrl(item) {
      let domain = "https://back-ai.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
  },
  async created() {
    this.fetchServices();
  },
};
</script>
  
<style></style>
  