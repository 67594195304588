<template>
  <div>
    <div style="height: 75px;"></div>
    <div class="globalRadiusView py-10" style="padding: 1%;">


      <v-row class=" d-flex justify-center align-center">
        <template v-for="(item, index) in mainViews">
          <v-col class=" d-flex justify-center align-center" md="4" :key="index" style="margin-top: 5%;">
            <v-card class="cardAgent">
              <v-card-title>
                <div style="height: 200px; width: 300px; text-align: center;">
                  <img :src="getImgUrl(item.image.path)" style=" height: 200px; width: 300px; object-fit: cover;" />
                </div>
              </v-card-title>
              <v-card-text class=" d-flex justify-center align-center">
                <h2> {{ $t(item.name) }} </h2>

              </v-card-text>

            </v-card>
          </v-col>
        </template>
      </v-row>


    </div>

  </div>
</template>
      
<script>
import axios from "axios";

export default {
  name: "agent",

  data() {
    return {
      mainViews: []
    };
  },
  methods: {
    getImgUrl(item) {
      let domain = "https://back-ai.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
  },
  async created() {
    try {
      //loading
      const response = await axios.get("agent", {
        params: {
        },
      });
      this.mainViews = response.data.data;
    } catch (err) {
    } finally {
    }
  },
};
</script>
      
<style>
.cardAgent {}
</style>
      