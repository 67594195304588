<template>
  <div class="globalRadiusViewContact py-10">
    <v-col class="text-center" md="12">
      <h1 style="color: var(--base-color1);">Contact Us</h1>
    </v-col>
    <v-row>
      <v-col md="1"></v-col>
      <v-col md="5">
        <v-col>
          <v-text-field :dark="!$store.state.isDarkMode" style="color: var(--bg-color-font-con) !important;"
            :label="$t('name')" v-model="structurMessage.name">
          </v-text-field>
        </v-col>
        <v-col>
         
          <v-row>
            <v-col md="6">
              <v-text-field :dark="!$store.state.isDarkMode" :rules="emailRule()" style="color: var(--bg-color-font-con) !important;"
                :label="$t('hr.employee.email')" v-model="structurMessage.mail">
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field type="number" :dark="!$store.state.isDarkMode"
                style="color: var(--bg-color-font-con) !important;" :label="$t('phone number')"
                v-model="structurMessage.phone_number"></v-text-field>
            </v-col>
           
          </v-row>
        </v-col>
        <v-col>
          <v-row style="margin-left: 2%; color: var(--bg-color-font-con) !important;">{{ $t('Message') }}</v-row>
          <v-row style="margin-top: 5%; margin-left: 1%;">
            <textarea
              style="width: 100%; border: 0.5px solid var(--bg-color-font-con); border-radius: 20px; padding: 10px; color: var(--bg-color-font-con) !important;"
              v-model="structurMessage.message" rows="6">
        </textarea>
          </v-row>
        </v-col>
        <v-col md="8">
          <v-btn :loading="btnloading" color="blue" dark @click="sendMessage">
            <span>{{ $t('Send Mesaage') }}</span>
          </v-btn>
        </v-col>

      </v-col>
      <v-col md="1"></v-col>
      <v-col md="4">
        <div style="width: 473px; height: 371px;">
          <v-fade-transition mode="out-in">
            <v-img src="@/assets/img-contactUs.png" style="max-width: 473px; max-height: 371px; "></v-img>
          </v-fade-transition>
        </div>
      </v-col>
      <v-col md="1"></v-col>
    </v-row>
  </div>
</template>
      
<script>
import axios from 'axios';
import { emailRule, requiredRule } from "@/helpers/validation-rules";

export default {
  name: "contact",

  data() {
    return {
      structurMessage: {
        name: null,
        phone_number: null,
        mail: null,
        message: null,
      },
      btnloading: false,
    };
  },
  methods: {
    emailRule() {
      return emailRule
    },
    requiredRule() {
      return requiredRule
    },
    async sendMessage() {
      try {
        this.btnloading = true;
        if(!this.structurMessage.mail &&  !this.structurMessage.phone_number){
          this.$Notifications(
              this.$t('You must enter your email or phone number'),
              { rtl: true, timeout: 2500 },
              "error"
            );
        }
        const response = await axios.post("message", this.structurMessage);
      } catch (err) {
      } finally {
        this.btnloading = false;
      }
    },
  },
  created() {
  },
};
</script>
      
<style></style>
      