<template>
  <div class="globalRadiusViewFeature">
    <img src="@/assets/Img-3.jpg" class="home-img-about" />
    <v-row style="margin-top: 3%; position: absolute;">
      <v-col md="1">
      </v-col>
      
      <v-col md="10">
        <v-row>
          <v-col md="4">
            <v-col v-for="(btn, index) in buttons" :key="index">
              <v-btn style="min-width: 250px;" :loading="btnloading"  :style="{ backgroundColor: btn.backColor }" @click="changeColor(index)">
              <span :style="{ color: btn.textColor }">{{ $t(btn.title) }}</span>
            </v-btn>
            </v-col>
          
            <!-- <div style="height: 40px; width: 217px; margin-right: 2%; background-color: #FFFFFF;">
            </div> -->
          </v-col>
          <v-col  md="8">
            <iframe v-if="videoUrl" height="478" width="850" :src="videoUrl" frameborder="0" allowfullscreen></iframe>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="1"></v-col>
    </v-row>
  </div>
</template>
        
<script>
import axios from "axios";

export default {
  name: "agent",

  data() {
    return {
      buttons: [],
      mainViews: [
      ],
      videoUrl: '',
    };
  },
  methods: {
    changeColor(index) {
      this.buttons.forEach((btn, i) => {
        if (i === index) {
          btn.backColor = '#384371'; 
          btn.textColor = '#FFFFFF';
          this.videoUrl = btn.url;
        } else {
          btn.backColor = ''; 
          btn.textColor = '';
        }
      });
    }
  },
  async created() {
    try {
      //loading
      const response = await axios.get("product-video", {
        params: {
        },
      });
      this.mainViews = response.data.data;
      this.mainViews.forEach((el) => {
        this.buttons.push(Object.assign({textColor: '', backColor: ''}, el));
      });
      this.changeColor(0);
    } catch (err) {
    } finally {
    }
  },
};
</script>
        
<style>
.cardAgent {}
</style>
        