<template>
  <div class="globalRadiusView ">
    <!-- <img src="@/assets/Img-5.jpg" class="home-img" />  // homeList  -->
    
    <video v-if="$store.state.isDarkMode" autoplay="autoplay" loop muted class="home-img-dark">
      <source  src="@/assets/imgV.mp4" type="video/mp4">
    </video>
    <video v-if="!$store.state.isDarkMode" autoplay="autoplay" loop muted class="home-img">
      <source  src="@/assets/Light.mp4" type="video/mp4">
    </video>
    <!-- <video  autoplay="autoplay" loop muted class="home-img">
    </video> -->
    <div style="margin-left: 5%; margin-top: 15%; width: 30%; position: absolute;">
      <v-fade-transition mode="out-in">
        <v-img src="@/assets/Logo-white.png" style=" width: 90%;  "></v-img>
        <!-- <v-img v-else src="@/assets/Logo-dark.png" style=" width: 90%;  "></v-img> -->
      </v-fade-transition>
    </div>
    <div style="margin-left: 5%; margin-top: 27%; width: 50%; position: absolute;">
      <v-row>
        <h1 style=" font-size: 40px; color: white; font-weight: lighter;"> {{ $t('Customize Your Business') }}</h1>
      </v-row>
      <v-row>
        <h1 style="width: 240px; font-size: 55px; color: white;"> {{ $t('With One') }}</h1>
        <h1 style="width: 80px; font-size: 55px; color: var(--base-color1);"> {{ $t('AI') }}</h1>
        <h1 style=" font-size: 55px; color: white;"> {{ $t('Platform') }}</h1>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "home",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      homeList: "home/getHomeList",
    }),

  },
  methods: {
    ...mapActions({
      fetchHomeList: "home/fetchHomeList",
    }),
  },
  created() {
    console.log(this.$store.state.isDarkMode);
    // this.fetchHomeList();
  },
};
</script>
      
<style></style>
      