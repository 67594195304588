<template >
  <div >
    
      <Home class="home-content" />
      <Services class="services-content"  />
      <About class="about-content"  />
      <Clients class="clients-content"  />
      <Feature class="about-content" />
      <Article class="article-content"  />
      <Contact class="contact-content"  />
      <Map/>
      
  </div>

</template>

<script>
import axios from "axios";
import Services from "./services/servicesIndex";
import Clients from "./clients/clientsIndex";
import Agents from "./agents/agentsIndex";
import About from "./about/aboutIndex";
import Contact from "./contact/contactIndex";
import Article from "./article/articleIndex";
import Home from "./home/homeIndex";
import Feature from "./feature/featureIndex.vue";
import Map from "./contact/map.vue"

export default {
  name: "DashboardDashboard",
  components:{
    Services,
    Clients,
    Agents,
    About,
    Contact,
    Article,
    Home,
    Feature,
    Map,
  },
  data() {
    return {
      titleHome: null,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  watch: {

   
  },
  methods: {
    handleScroll(event) {
      // You can access the scroll position using window.scrollY
      if (window.scrollY > 700 && window.scrollY < 702 ) {
       console.log("object");
       console.log("jdbjsabgkjfbg");
      }
    }
  },
  async created(){
    this.$store.commit("SET_DARK_MODE", false);
    localStorage.setItem('theme', 'light');
    document.documentElement.setAttribute('data-theme', 'light');
  },
};
</script>
